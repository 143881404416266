// @flow strict
import React from 'react';
import { withPrefix } from 'gatsby';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles['author']}>
      <img
        src={withPrefix(author.photo)}
        className={styles['author__photo']}
        width="75"
        height="75"
        alt={author.name}
      />

      <div
        style={{
          display: 'inline-flex',
          flexWrap: 'wrap',
        }}
      >
        <p className={styles['author__bio']}>
          {author.bio}
          <a
            className={styles['author__bio-twitter']}
            href={getContactHref('github', author.contacts.github)}
            rel="noopener noreferrer"
            target="_blank"
          >
            <strong>Find me</strong> on GitHub
          </a>
        </p>
        <p>
          <a href="https://www.buymeacoffee.com/pbtl" target="_blank" className={styles['coffee']}>
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-violet.png"
              alt="Buy Me A Coffee"
            />
          </a>
        </p>
      </div>
    </div>
  );
};

export default Author;
